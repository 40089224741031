import React from "react";

import { useAppSelector } from "app";

import {
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  useTheme,
} from "@mui/material";

import { StepperItem } from "./StepperItem";

const steps = ["Select executors", "Attachments"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    margin: 0,
    top: 4,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.secondary.main} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
  },
}));

const ColorLibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(() => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <StepperItem
        completed={completed}
        active={active}
        name="Info"
        label="createChartDrawer.step1_label"
      />
    ),
    2: (
      <StepperItem
        completed={completed}
        active={active}
        name="UploadCloud"
        label="createChartDrawer.step2_label"
      />
    ),
  };

  return (
    <ColorLibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}

export const ChartCreationStepper = () => {
  const { chartCreationStep } = useAppSelector((state) => state.project);
  const theme = useTheme();

  return (
    <Stack
      position="fixed"
      width="552px"
      height="100px"
      justifyContent="center"
      top={80}
      zIndex={10}
      bgcolor={theme.palette.background.default}
    >
      <Stack>
        <Stepper
          alternativeLabel
          activeStep={chartCreationStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon} />
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Stack>
  );
};
