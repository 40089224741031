import { MimeTypesEnum } from "./MimeTypes";
import { IPersonalData } from "./organizations";

export interface IMessageCorrespondent {
  type: string | null;
  userId: number;
  longName: string | null;
  surname: string | null;
  name: string | null;
}

export enum MessageAuthorType {
  USER = "user",
  SYSTEM = "system",
}

export enum MessageType {
  INCOMING = "Incoming",
  OUTGOING = "Outgoing",
}

export interface IAttachLink {
  contextOfTheDocument: string;
  contractId: number | null;
  createdAt: string;
  dateOfSigning: string;
  expirationDate: string;
  id: number;
  incomingDocumentId: number | null;
  longNameOfTheDocument: string;
  manualId: number | null;
  messageId: number;
  minutesInternalMeetingId: number | null;
  oadId: number | null;
  outgoingDocumentId: number | null;
  powerOfAttorneyId: number | null;
  regularTaskId: number | null;
  sequenceNumber?: string | number;
  serviceNoteId: number | null;
  shortNameOfTheDocument: string;
  supplementaryAgreementId: number | null;
  taskId: number | null;
  typeOfTheDocument: string;
  typeOfAttachment: string;
  updatedAt: string;
  workplanId: number | null;
  link?: string;
  documentName?: string;
}
export interface IMessage {
  messageText: string;
  topic: string;
  messageDate: string;
  viewingDate: string | null;
  messageNumber: number;
  additionalInformation: string | null;
  messageType: string;
  correspondent: IMessageCorrespondent;
  messageViewed?: boolean;
}

export interface IChatCorrespondent {
  userId?: number;
  type: MessageAuthorType;
  fullName?: string;
  surname?: string;
  name?: string;
  avatarLink: string | null;
}

export interface IChatItem {
  id: number;
  messageType: MessageType;
  correspondent: IChatCorrespondent;
  messageViewed: boolean;
  viewingDate: Date | string | null;
  messageText: string;
  attachedFilesCount: number;
  hasAttachedLink: boolean;
  dateOfTheMessage: Date | string | null;
  unreadMessagesCount: number;
}

export interface ISendMessage {
  messageAuthorUserId?: number;
  messageRecipientEmployeeIds: number[];
  textOfTheMessage: string;
  topicOfTheMessage?: string;
  importantMessage: boolean;
  typeOfTheMessageAuthor: string;
  typeOfTheMessageRecipient: string;
  isAttachedDocument: boolean;
  files: File[] | null;
}

export interface IMessageInfo {
  messageText: string;
  topic?: string;
  messageDate: string;
  viewingDate: string | undefined;
  messageNumber: number;
  additionalInformation?: string | null;
  messageType: string;
  correspondent: ICorrespondent[];
  messageViewed?: boolean;
  isImportant?: boolean;
  id: number;
}

export interface IAttachment {
  fileFormat: MimeTypesEnum;
  fileName: string;
  fileNameForSystem: string;
  convertedPdfFileName?: string;
}

export interface IMessageRecipients {
  dateOfViewingOfTheMessage?: Date;
  employeeId: number;
  messageViewed?: boolean;
  userId: number;
  personalData: IPersonalData;
}

export interface IAttachDocument {
  contextOfTheDocument: string;
  id: number;
  longNameOfTheDocument: string;
  shortNameOfTheDocument: string;
  typeOfTheDocument: string;
  correspondenceId: number;
  contractId: number;
  messageId: number | null;
  regularTaskId: number | null;
  taskId: number | null;
  supplementaryAgreementId: number | null;
  externalLinkUrl?: string;
}

export interface MessageViewResponse {
  id: number;
  userId: number;
  messageId: number;
  textOfTheMessage: string;
  topicOfTheMessage?: string;
  dateOfViewingOfTheMessage?: Date;
  messageViewed?: boolean;
  importantMessage: boolean;
  additionalInformation?: string;
  dateOfTheMessage: Date;
  deactivationDate?: Date;
  messageRecipients: IMessageRecipients[];
  attachments: IAttachment[];
  author?: IAuthor;
  document: IAttachDocument;
}

export interface IAuthor {
  id: number;
  userId: number;
  employeeId: number;
  personalData: IPersonalData;
  avatarLink: string | null;
}
export interface ConversationMessage {
  id: number;
  textOfTheMessage: string;
  createdAt: string;
  hasAttachedFile: boolean;
  hasAttachedLink: boolean;
  inSendingProcess?: boolean;
  messageViewed?: boolean;
}
export interface ConversationUser {
  id: number;
  personalData: IPersonalData;
}

export interface ConversationResponse {
  message: ConversationMessage;
  user: ConversationUser;
}

export interface IViewMessage {
  id: number;
  view: boolean;
}

export enum TheMessageRecipientEnum {
  user = "user",
  system = "system",
}
export interface ICorrespondent {
  type: "user" | "system";
  longName?: string;
  name?: string;
  surname?: string;
  userId?: number;
  avatarLink: string | null;
}
export interface IConversationList {
  theme: string;
  recipients?: number;
}

export enum MessageAttachmentsType {
  FILES = "files",
  LINKS = "links",
}

export enum MessengerModeType {
  CHAT = "chat",
  MAIL = "mail",
}

export enum DocumentTypeOfTheDocumentEnum {
  contract = "contract",
  supplementaryAgreement = "supplementary_agreement",
  incomingDocument = "incoming_document",
  outgoingDocument = "outgoing_document",
  oad = "oad",
  serviceNote = "service_note",
  workplan = "workplan",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
  // message = "message",
  task = "task",
  regularTask = "regular_task",
}

export interface IFiltersMessageCorrespondents {
  userId: number;
  type: string;
  correspondentName: string;
}
