import styled from "styled-components";

import { formGroupClasses } from "@mui/material";

export const RadioButtonsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0, 4, 0)};
  margin-top: ${({ theme }) => theme.spacing(-8)};

  .${formGroupClasses.root} {
    align-items: flex-start;
  }
`;
