import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";
import { FormRadio } from "@sbm/ui-components";
import { IProjectProjectParticipants, IProjectStakeholder } from "@types";

import { RadioButtonsWrapper } from "./styles";

interface Props {
  data: IProjectProjectParticipants[];
  control: Control<IProjectStakeholder>;
}

export const ShareDocumentContainer = ({ data, control }: Props) => {
  const { t } = useTranslation();

  const options = data.map((item) => {
    return {
      option: (
        <Stack display="flex" flexDirection="column" mt={6} mb={-5}>
          <Typography variant="subtitle1">{item.shortName}</Typography>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            color="text.secondary"
          >
            {item.briefTextOrganizationName}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {t(item.roleForParticipant)}
          </Typography>
        </Stack>
      ),
      value: item.id.toString(),
    };
  });

  return (
    <Stack mt={4}>
      {data.length > 1 ? (
        <RadioButtonsWrapper>
          <FormRadio
            name="participantOfProjectStakeholderId"
            control={control}
            values={options}
            className="radio__buttons"
            flexDirection="column"
            fullWidth={false}
          />
        </RadioButtonsWrapper>
      ) : null}
    </Stack>
  );
};
