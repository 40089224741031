import { OperatesOnBasisEnum } from "./contracts";
import { FunctionEnum } from "./structuralUnits";

export interface OrganizationsListItem {
  id: number;
  fullName: string;
  shortName: string;
  taxId: string;
  fullNameOfTheManager: string;
  counterpartysCategory?: string | null;
  typeOfService: string;
  activityIndicator: true;
  belongingToTheEvGroup: boolean;
  widgetOn: boolean;
  taxationSystem: string | null;
  createdAt: string;
  updatedAt: string;
}

export enum StandartPhoneTypeEnum {
  fax = "fax",
  general = "general",
  reception = "reception",
  secretary = "secretary",
  accounting = "accounting",
  contract = "contract",
  legal = "legal",
}

export enum TaxSystemEnum {
  gst = "GST",
  sts = "STS",
  asts = "ASTS",
  pst = "PST",
  uat = "UAT",
}

export enum OrganizationEmployeeOptionEnum {
  employee = "employee",
  management = "management",
  firstPerson = "first_person",
}

export interface IOrganizationEmployee {
  id?: number;
  personalDataId?: number;
  divisionExternalOrganization?: string;
  positionExternalOrganization?: string;
  operatesOnBasis?: OperatesOnBasisEnum;
  phone?: string | number;
  email?: string;
  additionalInformation?: string;
  typeStaff?: OrganizationEmployeeOptionEnum;
  personalData?: IPersonalData;
  rightToSignStaff?: boolean;
}

export interface IOrganization {
  isActive: boolean | undefined;
  additionalOptions: boolean | undefined;
  id?: number;
  fullTextOrganizationName: string;
  briefTextOrganizationName: string;
  shortName?: string;
  inn: string | undefined;
  kpp: string | undefined;
  ogrn: string | undefined;
  okpo: string | undefined;
  category: string;
  taxSystem: string;
  inHolding: boolean;
  belongingToTheEvGroup: boolean;
  validity: boolean;
  widgetOn: boolean;
  serviceType?: string;
  updatedAt?: string;
  createdAt?: string;
  abbreviation?: string;
  name?: string;
  employees?: IOrganizationEmployee[];
  externalOrganizationStaff?: IOrganizationEmployee[];
  contacts?: IContacts;
  website?: string;
  phone?: {
    number: string;
    phoneType?: StandartPhoneTypeEnum;
    customPhoneType?: string;
  }[];
  email?: {
    email: string;
    emailType?: string;
  }[];
  address?: {
    fullAddress?: string;
    addressType?: AddressTypeEnum;
  }[];
}

export interface IStructuralUnit {
  id: number;
  organizationId: number;
  parentStructuralUnitId: number | null;
  mainGoverningBodyId: number;
  governingBodyId: number | null;
  functionalBlockId: number | null;
  departmentId: number | null;
  divisionId: number | null;
  sectorId: number | null;
  directorateForManufacturingId: number | null;
  unitForManufacturingId: number | null;
  groupForManufacturingId: number | null;
  additionalInformation: string;
  nameOfStructuralUnit: string;
  dateOfListingStructuralUnit: string;
  dateDelistingStructuralUnit: string | null;
  briefTextOrganizationName: string | null;
  structuralUnitType: string;
  numberInTheList: number;
  validity: boolean;
  node: string[];
  createdAt: string;
  updatedAt: string;
  staffUnits: any[];
  staffUnitsTotal: number;
  staffUnitsFilled: number;
  staffUnitsVacancies: number;
  staffUnitsInternalAllocations: number;
  functionsForStructuralUnit?: FunctionsForStructuralUnitType;
  positionName?: string;
}

type FunctionsForStructuralUnitType = {
  id: number;
  structuralUnitId: number;
  createdAt: string;
  updatedAt: string;
  function: FunctionEnum;
}[];

export interface IPosition {
  id: number;
  categoryPersonnelId: number | null;
  subcategoryPersonnelId: number | null;
  functionalGroupPersonnelId: number | null;
  baseMonthlySalaryId: null;
  namePosition: string;
  additionalInformation: string | null;
  createdAt: string;
  updatedAt: string;
}

export enum GenderEnum {
  male = "male",
  female = "female",
}

export interface IPersonalData {
  id: number;
  longName: string | null;
  surname: string;
  name: string;
  fullName?: string;
  shortName?: string;
  patronymic?: string;
  additionalInformation: string | null;
  createdAt: string;
  updatedAt: string;
  gender?: GenderEnum;
  employees?: IEmployee[];
  personPhoneNumber?: {
    phoneNumber?: {
      phoneNumber: string;
    };
  };
  personEmail?: {
    email?: { email: string };
  };
}

interface IAssistant {
  additionalInformation: string | null;
  namePosition: string;
  positionId: number;
  staffUnitId: number;
  structuralUnit: Pick<IStructuralUnit, "id" | "nameOfStructuralUnit">;
  personalData: IPersonalData;
}

export interface IEmployee {
  id: number;
  staffUnitId: number;
  personalDataId: number;
  personnelNumber: string;
  dateOfRecruitment: string;
  dateOfDismissal: null;
  additionalInformation: string;
  validity: true;
  createdAt: string;
  updatedAt: string;
  personalData: IPersonalData;
  staffUnit?: IStaffUnit;
  externalSubstitutions?: IEmployee[];
  hasExternalSubstitutions?: boolean;
  typeEmploymentRelationship?: TypeEmploymentRelationshipEnum;
  assistant?: IAssistant;
}

export interface IEmployeeInHolding {
  employeeId: number;
  externalSubstitutions?: IEmployee[];
  personalData: IPersonalData;
  externalSubstitutionId: number | null;
  structuralUnit?: IStructuralUnit;
}

export interface IExternalOrganizationStaff {
  personalData: IPersonalData;
  positionExternalStaff: string;
  additionalInformation: string;
  divisionExternalOrganization: string;
  email: string;
  id: number;
  organization: IOrganization;
  organizationId: number;
  personalDataId: number;
  phone: string;
  typeStaff: OrganizationEmployeeOptionEnum;
  operatesOnBasis: OperatesOnBasisEnum;
  validity: boolean;
  positionExternalOrganization?: string;
}

export interface IStaffUnit {
  id: number;
  positionName: string;
  numberForTheList: number;
  headOfStructuralUnit: boolean;
  organization?: IOrganization;
  organizationId?: number;
  positionId?: number;
  position?: IPosition;
  structuralUnit?: IStructuralUnit;
}

export interface IConstructionComplex {
  id: number;
  constructionTitleId: number;
  propertyDeveloperId: number | null;
  requisitioningOfficeId: number | null;
  fullTextConstructionComplexName: string;
  briefTextConstructionComplexName: string;
  additionalInformation: string | null;
  employeeId: number | null;
  createdAt: string;
  updatedAt: string;
}

export enum EnumEmployeeType {
  employee = "employee",
  substitution = "substitution",
}

export enum EnumOrganizationViewMode {
  cardMode = "cardMode",
  lineMode = "lineMode",
}

export enum OrganizationCategoryEnum {
  category1 = "category1",
  category2 = "category2",
  category3 = "category3",
}

export enum OrganizationListEnum {
  category = "category",
  activityIndicator = "activityIndicator",
}

export enum CurrencyEnum {
  ruble = "ruble",
  dollar = "dollar",
  euro = "euro",
}

interface IPhoneNumbersOfOrganization {
  id: number;
  phoneNumber: { id: number; phoneNumber: string };
  standartPhoneType: StandartPhoneTypeEnum;
}

interface IEmailsOfOrganization {
  id: number;
  additionalInformation: string;
  email: {
    id: number;
    email: string;
  };
}

interface IBankAccountsOfOrganization {
  id: number;
  currency: CurrencyEnum;
  settlementAccount: string;
  bank: {
    id: number;
    bankName: string;
    bankIdentifierCode: string;
    correspondentAccount: string;
  };
}

export enum AddressTypeEnum {
  legal = "legal",
  factual = "factual",
  postal = "postal",
  other = "other",
}

interface IAddressesOfOrganization {
  id: number;
  addressType: AddressTypeEnum;
  address: {
    id: number;
    textForFullAddress: string;
  };
}

interface IContacts {
  addressesOfOrganization: IAddressesOfOrganization[];
  bankAccountsOfOrganization: IBankAccountsOfOrganization[];
  emailsOfOrganization: IEmailsOfOrganization[];
  phoneNumbersOfOrganization: IPhoneNumbersOfOrganization[];
  website: { id: number; website: string }[];
}

export interface IOrganizationStaffListColumnInTable {
  id: number;
  name: string;
  structuralUnitId: number;
  staffUnitId: number;
  positionId: number;
  node: number[];
  status: string;
  validity: string;
  nameOfPosition: string;
  dateOfEstablishmentStaffUnit: string;
  dateOfWithdrawalStaffUnit: string;
  additionalInformation: "";
  headOfStructuralUnit: boolean;
  validityBoolean: boolean;
}

export enum TypeEmploymentRelationshipEnum {
  typeA = "type_a",
  typeB = "type_b",
  typeC = "type_c",
}

export enum ChartOptionsEnum {
  expand = "expand",
  collapse = "collapse",
  showEmployees = "show_employees",
  hideEmployees = "hide_employees",
}

export enum FileAttachedToOrganizationTypeOfAttachmentEnum {
  charter = "charter",
  powerOfAttorney = "power_of_attorney",
  order = "order",
  directive = "directive",
  certificateOfRegistration = "certificate_of_registration",
  auxiliaryFile = "auxiliary_file",
}

export interface IOrganizationFile {
  items: {
    id: number;
    uploaderId: number;
    additionalInformation: string | null;
    createdAt: string;
    fileAppointment: FileAttachedToOrganizationTypeOfAttachmentEnum;
    fileFormat: string;
    fileId: number;
    fileName: string;
    fileNameForSystem: string;
    uploadedBy: string;
  }[];
}

export interface IAssistantStaff {
  staffUnitId: number;
  longName: string;
  shortName: string;
  personalDataId: number;
  employeeId: number;
  structuralUnitId: number;
  nameOfStructuralUnit: string;
  namePosition: string;
}

export interface IOrgStaffUnitDetails {
  employeeName: string;
  nameOfStructuralUnit: string;
  numberForTheList: null | number;
  organizationName: string;
  positionName: null | string;
  substitutionStatus: string;
  validity: boolean;
  assistant: IAssistantStaff;
}

export enum SubstitutionStatusEnum {
  fullTimeEmployee = "fullTimeEmployee",
  externalSubstitution = "externalSubstitution",
}
