import { createSlice } from "@reduxjs/toolkit";
import { TasksTabEnum, TasksViewEnum } from "@types";

interface IChart {
  chartCreationStep: number;
  currentTab: TasksTabEnum;
  currentChartView: TasksViewEnum;
}

export const initialStateProject: IChart = {
  chartCreationStep: 0,
  currentTab: TasksTabEnum.incoming,
  currentChartView: TasksViewEnum.table,
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialStateProject,
  reducers: {
    resetChartCreationStep: (state) => {
      state.chartCreationStep = 0;
    },
    setChangeChartCreationStep: (state, action) => {
      state.chartCreationStep = state.chartCreationStep + action.payload;
    },
    setChartTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setChartViewTab: (state, action) => {
      state.currentChartView = action.payload;
    },
  },
});

export const {
  setChangeChartCreationStep,
  resetChartCreationStep,
  setChartTab,
  setChartViewTab,
} = projectSlice.actions;

export default projectSlice.reducer;
