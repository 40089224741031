import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";
import {
  IProjectProjectParticipants,
  IProjectStakeholder,
  IShareDocument,
} from "@types";

import { ShareDocumentContainer } from "../../../../containers";

export const ProjectParticipantsPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: {
    participants: IProjectProjectParticipants[];
    documentId: number;
    constructionComplexId: number;
    shareDocument: (data: IShareDocument) => void;
    isLoading: boolean;
  };
}) => {
  const { open, onClose, data } = props;
  const { t } = useTranslation("project");

  const { control, handleSubmit } = useForm<IProjectStakeholder>();

  const { participantOfProjectStakeholderId } = useWatch({
    control,
  });

  const onSubmit = handleSubmit(async () => {
    if (participantOfProjectStakeholderId) {
      data.shareDocument({
        documentId: data.documentId,
        participantOfProjectStakeholderId: +participantOfProjectStakeholderId,
        constructionComplexId: data.constructionComplexId,
      });
    }
    onClose();
  });

  const getDisableState = () => {
    if (data.participants.length > 1) {
      return !participantOfProjectStakeholderId;
    }
  };

  const getContentTitle = () => {
    if (data.participants.length === 0) {
      return t("share_document_empty_participants_text");
    } else if (data.participants.length > 1) {
      return t("share_document_participants_choose_text");
    }
  };

  const getButtonText = () => {
    if (data.participants.length === 0) {
      return t("ok");
    } else if (data.participants.length > 1) {
      return t("confirm");
    }
  };

  const content = {
    title: `${getContentTitle()}`,
    body: <ShareDocumentContainer data={data.participants} control={control} />,
    secondaryButton:
      data.participants.length > 1 ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={onClose}
        >
          {t("back")}
        </Button>
      ) : null,
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={data.participants.length > 1 ? onSubmit : onClose}
        loading={data.isLoading}
        disabled={getDisableState()}
      >
        {getButtonText()}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
