import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

import { Stack } from "@mui/material";

export const RubricsCountWrapper = styled(Stack)`
  ${FLEX_COLUMN_FULL}
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => `${(theme.spacing(1), theme.spacing(3))}`};
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  font-size: 13px;
`;
